//平台分佣收益接口
import { axios } from '@/utils/request'


/*
 * @Description: 平台收益明细分页查询
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-02
 */
export function findPlatformCommissionPageList(parameter){
	return axios({
		url: "/platformCommissionRecord/page",
		method: 'post',
		params:parameter.page,
		data: parameter.queryParam
	})
}

/**
 * @param {Object} parameter
 * @Description: 平台收益统计
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-02
 */
export function getPlatformCommissionData(parameter){
	return axios({
		url: '/platformCommissionRecord/totalPrice',
		method: "post",
		data: parameter
	})
}